import React from 'react'
import LocaleSelect from './LocaleSelect'
// import {FormattedMessage, FormattedNumber} from 'react-intl'
import logo from '../../assets/img/FMSf-03.png'
import NavLinks from './NavLinks'
import {Link} from 'react-router-dom'
import Footer from './Footer'

class Layout extends React.Component {

    state = {
        menuOpen: false
    }

    onMenuToggle = () => {
        this.setState({
            menuOpen: !this.state.menuOpen
        })
    }

    render(){
        return(
            <div className="m_wrapper">
                <header className="m_header">
                    <div className="m_inner">
                        <div className="m_column m_column-logo">
                            <Link className="m_logo" to="/">
                                <img src={logo} alt="" />
                            </Link>
                        </div>
                        <div className={`m_column m_column-nav ${ this.state.menuOpen ? 'm_show' : '' }`}>
                            <nav className="m_nav">
                                <ul className="m_ul" onClick={this.onMenuToggle}>
                                    <NavLinks />
                                </ul>
                            </nav>
                        </div>
                         
                        <div className="m_column m_column-widgets">                                                     
                            <LocaleSelect />     
                            <button 
                                className={`m_hamburger-icon ${ this.state.menuOpen ? 'm_open' : '' }` }
                                onClick={this.onMenuToggle}
                            >
                                <span /><span /><span />
                            </button>                        
                        </div>
                    </div>
                    
                </header>
                {this.props.children}
                <Footer />
            </div>
        )
    }
}

export default Layout