import {
    SEND_CONTACT_MESSAGE,
    SEND_CONTACT_MESSAGE_FAILED,
    SEND_CONTACT_MESSAGE_RESET
} from '../actions/types'

const initialState = {}

const sendContactMailReducer = (state = initialState, action) => {
    switch(action.type){
        case SEND_CONTACT_MESSAGE:
            return {
                success: action.payload
            }
        case SEND_CONTACT_MESSAGE_FAILED:
            return {
                errors: action.payload
            }
        case SEND_CONTACT_MESSAGE_RESET:
            return initialState
        default:
            return state
    }
}

export default sendContactMailReducer