import React from 'react'
import {Link} from 'react-router-dom'
import img1 from '../../assets/img/pexels-adrianna-calvo-17679.jpg'
import img2 from '../../assets/img/entrepreneur-1340649_1920.jpg'
import img3 from '../../assets/img/gears-384744_1920.jpg'
import img4 from '../../assets/img/pexels-pixabay-47349.jpg'
import img5 from '../../assets/img/points-to-consider-before-buying-a-term-insurance-policy.jpg'
import {FormattedMessage} from 'react-intl'

class ProductsColumns extends React.Component {

    state = {
        products: [
            {
                id: 'foreign-exchange',
                image: img3,
                heading: (
                    <FormattedMessage 
                        id="products-foreign-exchange"
                        defaultMessage="Foreign Exchange"
                    />
                ),
                contentLeft: (
                    <FormattedMessage 
                        id="products-foreign-exchange-content-1"
                        defaultMessage="<p1>Foreign Exchange</p1><p2>FMS has a core client base mostly of European origin. Since these clients regularly visit our shores and in most instances purchase property locally, there exists a need for FMS to act as an intermediary when foreign monies are transferred to the country to conclude such deals. Furthermore, these clients need to service certain obligations such as rates and taxes on properties, telephone accounts and other related services, which also require monies to be sent to South Africa on a regular basis.</p2>"
                        values={{
                            p1: msg => <p className="m_p">{msg}</p>,
                            p2: msg => <p>{msg}</p>,
                        }}
                    />
                        
                ),
                contentRight: (
                    <FormattedMessage 
                        id="products-foreign-exchange-content-2"
                        defaultMessage="<p>Why use our foreign exchange services?</p>
                            <ul>
                                <li>Competitive exchange rates</li>
                                <li>Lower bank fees</li>
                            </ul>"
                        values={{
                            p: msg => <p className="m_p">{msg}</p>,
                            ul: msg => <ul>{msg}</ul>,
                            li: msg => <li>{msg}</li>
                        }}
                    />
                )
            },
            {
                id: 'private-banking',
                image: img4,
                heading: (
                    <FormattedMessage 
                        id="products-private-banking"
                        defaultMessage="Private Banking"
                    />
                ),
                contentLeft: (
                    <FormattedMessage 
                        id="products-private-banking-content-1"
                        defaultMessage="<p1>Private Banking</p1><p2>FMS is able to offer you competitively priced bank deposit products eliminating the need for you to interact with the banks yourself. The banking systems for Investec provide us with a secure environment through which we can open accounts and transact on your behalf.</p2>"
                        values={{
                            p1: msg => <p className="m_p">{msg}</p>,
                            p2: msg => <p>{msg}</p>,
                        }}
                    />
                ),
                contentRight: (
                    <FormattedMessage 
                        id="products-private-banking-content-2"
                        defaultMessage="<p>How you will benefit</p><ul><li>Personalized service</li><li>A dedicated administration team</li><li>Attractive interest rates that are not offered directly to the public</li><li>Low transactional charges other than those related to cash deposits</li><li>A range of deposit options including call, fixed and money market linked accounts</li></ul><p>Choose an account that suits your needs</p><ul><li>Daily call account</li><li>Money-market linked account</li><li>Term deposit account</li></ul>"
                        values={{
                            p: msg => <p className="m_p">{msg}</p>,
                            ul: msg => <ul>{msg}</ul>,
                            li: msg => <li>{msg}</li>
                        }}
                    />
                )
            },
            {
                id: 'investments',
                image: img2,
                heading: (
                    <FormattedMessage 
                        id="products-investments"
                        defaultMessage="Investments"
                    />
                ),
                contentLeft: (
                    <FormattedMessage 
                        id="products-investments-content-1"
                        defaultMessage="<p1>Investment Possibilities</p1><p2>Get independant guidance from experienced profesionals on the variety of overseas investment products available</p2>"
                        values={{
                            p1: msg => <p className="m_p">{msg}</p>,
                            p2: msg => <p>{msg}</p>,
                        }}
                    />
                ),
                contentRight: (
                    <FormattedMessage 
                        id="products-investments-content-2"
                        defaultMessage="<p>Service Providers</p><ul><li>Allan Gray</li><li>Investec</li></ul>"
                        values={{
                            p: msg => <p className="m_p">{msg}</p>,
                            ul: msg => <ul>{msg}</ul>,
                            li: msg => <li>{msg}</li>
                        }}
                    />
                )
            },
            {
                id: 'short-term-insurance',
                image: img1,
                heading: (
                    <FormattedMessage 
                        id="products-short-term-insurance"
                        defaultMessage="Short-Term Insurance"
                    />
                ),
                contentLeft: (
                    <FormattedMessage 
                        id="products-short-term-insurance-content-1"
                        defaultMessage="<p1>Short-Term Insurance</p1><p>As a First Monetary Solutions client, we know that you value personalised service and expert advice. When it comes to your insurance needs, wouldn’t you like to receive the same superior service?</p><p>First Monetary Solutions will provide a personalised solution that fits your specific needs.</p>"
                        values={{
                            p1: msg => <p className="m_p">{msg}</p>,
                            p: msg => <p>{msg}</p>,
                        }}
                    />
                ),
                contentRight: (
                    <></>
                )
            },
            {
                id: 'long-term-risk-insurance',
                image: img5,
                heading: (
                    <FormattedMessage 
                        id="products-long-term-risk-insurance"
                        defaultMessage="Long-Term Risk Insurance"
                    />
                ),
                contentLeft: (
                    <FormattedMessage 
                        id="products-long-term-risk-insurance-content-1"
                        defaultMessage="<p1>Long-Term Risk Insurance</p1><p>Life Insurance, Capital Disability Insurance & Severe Illness Benefit.</p>"
                        values={{
                            p1: msg => <p className="m_p">{msg}</p>,
                            p: msg => <p>{msg}</p>
                        }}
                    />
                ),
                contentRight: (
                    <FormattedMessage 
                        id="products-long-term-risk-insurance-content-2"
                        defaultMessage="<p>Service Providers</p><ul><li>Discovery Life</li><li>Altrisk</li><li>Sanlam</li><li>Momentum</li><li>Liberty Life</li><li>Old Mutual</li></ul>"
                        values={{
                            p: msg => <p className="m_p">{msg}</p>,
                            ul: msg => <ul>{msg}</ul>,
                            li: msg => <li>{msg}</li>
                        }}
                    />
                )
            }
        ]
    }

    render() {
        return this.state.products.map(item => {
            return (
                <div className="m_column m_column-12 m_product-column" key={item.id} id={item.id}>
                    <div className="m_box" >                        
                        <div className="m_box-overlay" style={{
                            backgroundImage: `url(${item.image})`
                        }}>
                            <h3 className="m_h3">{item.heading}</h3> 
                        </div>
                        <div className="m_box-overlay">
                            <div className="m_inner">
                                <div className="m_column m_column-6">
                                    <div>
                                        {item.contentLeft}
                                    </div>
                                    <div>
                                        <Link to="/contact" className="m_btn2">
                                            <FormattedMessage 
                                                id="btn-get-advice"
                                                defaultMessage="Get Advice"
                                            />
                                        </Link>
                                    </div>
                                </div>
                                <div className="m_column m_column-6">
                                    <div>
                                        {item.contentRight}
                                    </div>
                                </div>
                            </div>
                        </div>                       
                    </div>
                </div>
            )
        })
    }
}

export default ProductsColumns