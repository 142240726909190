import React from 'react'
import {NavLink} from 'react-router-dom' 
import {FormattedMessage} from 'react-intl'

const NavLinks = () => {
    return (
        <>
            <li className="m_li">
                <NavLink exact to="/" activeClassName="m_active">
                    <FormattedMessage 
                        id="menu-home"
                        defaultMessage="Home"
                    />
                </NavLink>
            </li>
            <li className="m_li">
                <NavLink exact to="/about" activeClassName="m_active">
                    <FormattedMessage 
                        id="menu-about"
                        defaultMessage="About"
                    />
                </NavLink>
            </li>
            <li className="m_li">
                <NavLink exact to="/services" activeClassName="m_active">
                    <FormattedMessage 
                        id="menu-services"
                        defaultMessage="Services"
                    />
                </NavLink>
            </li>
            <li className="m_li">
                <NavLink exact to="/products" activeClassName="m_active">
                    <FormattedMessage 
                        id="menu-products"
                        defaultMessage="Products"
                    />                    
                </NavLink>
            </li>
            <li className="m_li">
                <NavLink exact to="/contact" activeClassName="m_active" className="m_btn-contact">
                    <FormattedMessage 
                        id="menu-contact"
                        defaultMessage="Contact"
                    />
                </NavLink>
            </li>
        </>
    )
}

export default NavLinks