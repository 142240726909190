import React from 'react'
import {FormattedMessage} from 'react-intl'
import InfoColumns from '../ui/InfoColumns2'
import Content from '../layout/Content'
import stamp from '../../assets/img/FMSstamp-02.png'
import {Link} from 'react-router-dom'
import mtt1 from '../../assets/img/mtt1.jpg'
import mtt2 from '../../assets/img/mtt2.jpg'
import mtt3 from '../../assets/img/mtt3.jpg'
import mtt4 from '../../assets/img/mtt4.jpg'

class About extends React.Component {

    render(){
        return(
            <Content title="About | Finest Monetary Solutions" description="Established in 2006, we offer financial services to assist mainly foreign clientele in obtaining the best possible financial options when they decide to relocate to South Africa, whether on a permanent basis or as non-residents. We have added substantially to the ever growing client list through excellent client relations, good communication skills and the best advice and financial planning backed up by superb service at all levels.">
                <section className="m_section m_section-about-1" />
                <section className="m_section m_section-about-2">
                    <div className="m_inner">
                        <div className="m_column m_column-12">
                            <h1>
                                <FormattedMessage 
                                    id="about-heading-1"
                                    defaultMessage="Our story"
                                />
                            </h1>
                            <p className="m_p">
                            <FormattedMessage 
                                    id="about-par-1"
                                    defaultMessage="Established in 2006, we offer financial services to assist mainly foreign clientele in obtaining the best possible financial options when they decide to relocate to South Africa, whether on a permanent basis or as non-residents. We have added substantially to the ever growing client list through excellent client relations, good communication skills and the best advice and financial planning backed up by superb service at all levels."
                                /></p>
                            <div className="m_horline"></div>
                        </div> 
                    </div>
                </section>
                <section className="m_section m_section-about-3">
                    <div className="m_overlay"></div>
                </section>
                <section className="m_section m_section-about-4">
                    <div className="m_inner">
                        <div className="m_column m_column-12">
                            <h2 className="m_heading">
                                <FormattedMessage 
                                    id="about-heading-2"
                                    defaultMessage="Our expertise"
                                />
                            </h2>
                        </div>
                    </div>
                    <div className="m_inner">
                        <InfoColumns />
                    </div>
                </section>
                <section className="m_section m_section-about-5">
                    <div className="m_inner">
                        <div className="m_column m_column-12">
                            <FormattedMessage 
                                id="about-par-2"
                                defaultMessage="<p1><strong>Finest Monetary Solutions</strong> was established originally to assist foreign clientele, mainly German speaking individuals, with their day to day banking and other financial needs by executing transactions on their behalf and rendering advice on all financial services available. Since its inception in 2006, Finest Monetary Solutions has remained on the vanguard of the Financial Services industry through committing to excellent service and the best advice available.</p1><p2>As one of the fastest growing companies in the industry, we strive for ongoing improvement with regards to banking, investment portfolios and insurance products</p2>"
                                values={{
                                    p1: msg => (
                                        <p className="m_p">{msg}</p>
                                    ),
                                    p2: msg => (
                                        <p className="m_p">{msg}</p>
                                    ),
                                    strong: msg => (
                                        <strong className="m_themeFont">{msg}</strong>
                                    )
                                }}
                            />

                            <img className="m_img" src={stamp} alt="" />
                        </div>
                    </div>
                </section>

                <section className="m_section m_section-about-6">
                    <div className="m_inner" id="gisela-nieman">
                        <div className="m_column m_column-6 m_column-img">
                            <img src={mtt1} alt="" className="hexagon" />
                        </div>
                        <div className="m_column m_column-6">
                            <h4>
                                <FormattedMessage 
                                    id="team-gisela-nieman"
                                    defaultMessage="Gisela Niemann"
                                />
                            </h4>
                            <p className="m_p">
                                <FormattedMessage 
                                    id="team-gisela-nieman-pos"
                                    defaultMessage="DIRECTOR"
                                />
                            </p>
                            <p>
                                <FormattedMessage 
                                    id="team-gisela-nieman-message"
                                    defaultMessage="Our team is headed by Gisela Niemann who, though born in Namibia, hails from a strong German background and has a superb command the German language. Gisela has over thirty years’ experience in all sectors of the banking industry and left the sector as a member of a management team. Her vast experience and extensive contact base makes her one of the most sought after financial managers in the Helderberg region. During her career with different banking institutions she won numerous national awards, mainly service and sales related. Her warm and friendly personality has contributed to her immense popularity amongst clients and in the process she has created an extremely loyal client base."
                                />
                            </p>
                            <div className="m_horline"></div>
                            <Link to="/contact" className="m_btn">
                                <FormattedMessage 
                                    id="btn-contact-us"
                                    defaultMessage="Contact Us"
                                />
                            </Link>
                        </div>
                        
                    </div>
                    <div className="m_inner m_swop" id="lyle-maree">
                        <div className="m_column m_column-6 m_column-img">
                            <img src={mtt2} alt=""  className="hexagon" />
                        </div>
                        <div className="m_column m_column-6">
                            <h4>
                                <FormattedMessage 
                                    id="team-lyle-maree"
                                    defaultMessage="Lyle Maree"
                                />
                            </h4>
                            <p className="m_p">
                                <FormattedMessage 
                                    id="team-lyle-maree-pos"
                                    defaultMessage="FOREX"
                                />
                            </p>
                            <p>
                                <FormattedMessage 
                                    id="team-lyle-maree-message"
                                    defaultMessage="Lyle Maree joined the team on the 1st of May 2009. Hailing from Upington he had his own Financial Services franchise at the time, when the opportunity arose to join FMS. With extensive experience in FOREX Trading, investments, short term insurance, long term insurance, medical aids, trusts and estate planning, he oversees all these areas as a Key Individual, but specializes in the foreign currency trading of the company and its clients. Lyle handles all interactions and applications on behalf of clients with the South African Reserve Bank. With good moral ethics and dedication he integrated very smoothly into the company as these are the core elements that drive FMS."
                                />
                            </p>
                            <div className="m_horline"></div>
                            <Link to="/contact" className="m_btn">
                                <FormattedMessage 
                                    id="btn-contact-us"
                                    defaultMessage="Contact Us"
                                />
                            </Link>
                        </div>
                        
                    </div>
                    
                    <div className="m_inner" id="antje-braun">
                        <div className="m_column m_column-6 m_column-img">
                            <img src={mtt4} alt="" className="hexagon" />
                        </div>
                        <div className="m_column m_column-6">
                        <h4>
                                <FormattedMessage 
                                    id="team-antje-braun"
                                    defaultMessage="Antje Braun"
                                />
                            </h4>
                            <p className="m_p">
                                <FormattedMessage 
                                    id="team-antje-braun-pos"
                                    defaultMessage="ADMINISTRATION"
                                />
                            </p>
                            <p>
                                <FormattedMessage 
                                    id="team-antje-braun-message"
                                    defaultMessage="Antje Braun manages the reception area and switchboard of the office and sits at the heart of all operations of the office. She is also the first face you see when visiting FMS. She grew up in Hong Kong and has been living in South Africa for 17 years, and has been with FMS for over 12 years now. She also handles the transfers and queries on the banking platform."
                                />
                            </p>
                            <div className="m_horline"></div>
                            <Link to="/contact" className="m_btn">
                                <FormattedMessage 
                                    id="btn-contact-us"
                                    defaultMessage="Contact Us"
                                />
                            </Link>
                        </div>
                        
                    </div>
                </section>
            </Content>
        )
    }
}

export default About