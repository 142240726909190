import React from 'react'
import Helmet from 'react-helmet'

class Content extends React.Component {

    componentDidMount(){
        window.scrollTo(0, 0)
    }

    render(){
        return(
            <>
                <Helmet>
                    <title>{this.props.title ? this.props.title : 'Finest Monetary Solutions'}</title>
                    <meta name="description" content={this.props.description ? this.props.description : "Not just your financial partner, but committed to provide you with excellent service and the best financial advice available."} />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <div className="m_content">
                    {this.props.children}
                </div>
            </>
            
        )
    }
}

export default Content