import {
    SET_LOCALE
} from '../actions/types'

const localeReducer = (state = 'en', action) => {
    switch(action.type){
        case SET_LOCALE:
            return action.payload
        default:
            return state
    }
}

export default localeReducer