import React from 'react'
import facebook from '../../assets/img/iconmonstr-facebook-1-32.png'

class SocialMediaIcons extends React.Component{
    render(){
        return(
            <li className="m_sm-li">
                <a href="https://www.facebook.com/finestMS2006/?_rdc=1&_rdr" target="_blank" rel="noreferrer">
                    <img src={facebook} alt="" />
                </a>
            </li>
        )
    }
}

export default SocialMediaIcons