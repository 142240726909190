import admin from '../apis/admin'

import {
    SET_LOCALE,
    SEND_CONTACT_MESSAGE,
    SEND_CONTACT_MESSAGE_FAILED,
    SEND_CONTACT_MESSAGE_RESET
} from './types'

export const setLocale = value => {
    return (dispatch) => {
        dispatch({
            type: SET_LOCALE,
            payload: value
        })
    }
}

export const sendContactEmail = formValues => async dispatch => {
    try {
        
        const response = await admin.post('send-contact', formValues)

        console.log('success')

        dispatch({
            type: SEND_CONTACT_MESSAGE,
            payload: response.data
        })

    } catch (error) {
        console.log('error')
        dispatch({
            type: SEND_CONTACT_MESSAGE_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const resetSendContactEmail = () => {
    return (dispatch) => {
        dispatch({
            type: SEND_CONTACT_MESSAGE_RESET
        })
    }
}