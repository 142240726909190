import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import localeReducer from './localeReducer'
import sendContactMailReducer from './sendContactMailReducer'

const persistConfig = {
    key: 'root',
    storage,
    whiteList: ['localeState'],
    blacklist: ['sendContactMailState'],
}

const rootReducer = combineReducers({
    localeState: localeReducer,
    sendContactMailState: sendContactMailReducer,
    form: formReducer
})

export default persistReducer(persistConfig, rootReducer)