import React from 'react'
import Content from '../layout/Content'
import ContactForm from '../forms/ContactForm'
import {connect} from 'react-redux'
import {FormattedMessage} from 'react-intl'

class Contact extends React.Component {

    onSubmit = formValues => {
        console.log(formValues)
    }

    render(){
        return(
            <Content title="Contact Us | Finest Monetary Solutions" description="Service & Enquiries:
                +27 21 851 7955 / +27 21 851 8909
                Reception:
                +27 21 851 7955
                Address:
                1st Floor, The West Peak, 13 Lourensford Road, Somerset West, 7130
                Email:
                gisela@fm-s.co.za / lyle@fm-s.co.za">
                <section className="m_section m_section-contact">
                    <div className="m_inner">
                        
                        <div className="m_column m_column-6">
                            <h1 className="m_heading">
                                <FormattedMessage 
                                    id="contact-heading-1"
                                    defaultMessage="Contact Us"
                                />
                            </h1>
                            <p>
                                <FormattedMessage 
                                    id="contact-service-and-enquiries"
                                    defaultMessage="<span>Service & Enquiries:</span><br></br><a1>+27 21 851 7955</a1> / <a2>+27 21 851 8909</a2>"
                                    values={{
                                        span: msg => <span>{msg}</span>,
                                        br: () => <br />,
                                        a1: msg => <a href="tel:+27218517955">{msg}</a>,
                                        a2: msg => <a href="tel:+27218518909">{msg}</a>
                                    }}
                                />
                            </p>

                            <p>
                                <FormattedMessage 
                                    id="contact-reception"
                                    defaultMessage="<span>Reception:</span><br></br><a>+27 21 851 7955</a>"
                                    values={{
                                        span: msg => <span>{msg}</span>,
                                        br: () => <br />,
                                        a: msg => <a href="tel:+27218517955">{msg}</a>
                                    }}
                                />
                            </p>

                            <p>
                                <FormattedMessage 
                                    id="contact-address"
                                    defaultMessage="<span>Address:</span><br></br><a> 1st Floor, The West Peak, 13 Lourensford Road, Somerset West, 7130​</a>"
                                    values={{
                                        span: msg => <span>{msg}</span>,
                                        br: () => <br />,
                                        a: msg => <a href="https://goo.gl/maps/RWwpikZtgHinwEKCA" target="_blank" rel="noreferrer">{msg}</a>
                                    }}
                                />
                            </p>

                            <p>
                            <FormattedMessage 
                                    id="contact-email"
                                    defaultMessage="<span>Email:</span><br></br><a1>gisela@fm-s.co.za</a1> / <a2>lyle@fm-s.co.za</a2>"
                                    values={{
                                        span: msg => <span>{msg}</span>,
                                        br: () => <br />,
                                        a1: msg => <a href="mailto:gisela@fm-s.co.za">{msg}</a>,
                                        a2: msg => <a href="mailto:lyle@fm-s.co.za">{msg}</a>
                                    }}
                                />
                            </p>

                            <div className="m_horline" />
                            
                        </div>
                        <div className="m_column m_column-6">
                            <ContactForm onSubmit={this.onSubmit} />
                        </div>
                        <div className="m_column m_column-12">
                            <div className="m_map">
                                <iframe title="mfs-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3304.5776269855737!2d18.848613415729904!3d-34.08034013750127!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcdb5a81c1cc389%3A0x30065af6c6a7c0ee!2sFinest%20Monetary%20Solutions!5e0!3m2!1sen!2sza!4v1660203697379!5m2!1sen!2sza" width="600" height="450" style={{border: '0'}} allowfullscreen="" loading="lazy"></iframe>
                            </div>
                        </div>
                    </div>
                </section>
            </Content>
        )
    }
}

export default connect()(Contact)