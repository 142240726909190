import React from 'react'
import Content from '../layout/Content'
import ProductsColumns from '../ui/ProductsColumns'
import {FormattedMessage} from 'react-intl'

class Products extends React.Component {

    render(){
        return(
            <Content title="Products | Finest Monetary Solutions" description="Ensure a SECURE FINANCIAL FUTURE with tailored PLANNING">
                <section className="m_section m_section-products-1">
                    <div className="m_overlay"></div>
                    <div className="m_inner">
                        <div className="m_column">
                            <div className="n_column-inner ">
                                <p className="m_p">
                                    <FormattedMessage 
                                        id="products-main-message"
                                        defaultMessage="Ensure a<br></br><span1>SECURE FINANCIAL FUTURE</span1><br></br>with tailored<br></br><span2>FINANCIAL PLANNING</span2>"
                                        values={{
                                            br: () => <br />,
                                            span1: msg => <span>{msg}</span>,
                                            span2: msg => <span style={{color: '#7B752E'}}>{msg}</span>
                                        }}
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="m_section m_section-products-2">
                    <div className="m_inner">
                        <div className="m_column m_column-12">
                            <h1 className="m_heading">
                                <FormattedMessage 
                                    id="products-heading-1"
                                    defaultMessage="Products"
                                />
                            </h1>
                        </div>
                        <ProductsColumns />
                    </div>
                </section>
            </Content>
        )
    }
}

export default Products